<template>
    <div class="cached-stats-widget">
        <div class="row mb-3">
            <div class="col-12 d-flex align-items-center justify-content-end">
                <!-- <b-button variant="light" class="mr-auto" @click="onExport">
                    Export
                </b-button> -->
                <date-range-picker v-model="dateRange" opens="left" single-date-picker :ranges="false" class="mr-2 align-self-right" @update="dateChange">
                        <template v-slot:input="picker" style="min-width: 350px;">
                            <span>{{ picker.startDate | date('MMM dd, yyyy') }}</span>
                            <i class="mdi mdi-calendar-blank" style="padding-left: 7px;"></i>
                        </template>
            </date-range-picker>
            </div>
        </div>
        <div class="col-12 d-flex flex-column align-items-center">
            <p class="h3">Database Count</p>
            <b-table :items="tableOptions.items" :fields="tableOptions.columns" responsive class="text-center col-6" show-empty>
            <template slot="empty">
                No results found
            </template>
        </b-table>
        <p class="h6">Last Updated: {{format(lastUpdated, "dd/MM/yyyy HH:mm:ss")}} GMT</p>
        </div>
        
    </div>
</template>

<script>
import { compareAsc, subBusinessDays, isToday, startOfMonth, endOfMonth, format, startOfWeek, endOfWeek, addMonths, startOfQuarter, endOfQuarter, addQuarters, startOfYear, endOfYear, addYears } from 'date-fns';
import { getColor } from '@/lib/ColorLookup';
import { exportCsv } from '@/lib/Exporting';
import DateRangePicker from 'vue-daterange-picker-light';

export default {
    name: 'cached-stats',
    data() {
        return {
            tableOptions:{
                items:[],
                columns:["Type", "Amount"],
            },
            dateRange:{
                startDate:startOfWeek(new Date(Date.now())),
            },
            format:format,
            lastUpdated:new Date(0)
        }
    },
    components:{
        DateRangePicker
    },
    mounted() {
        this.requests(true);
    },
    beforeDestroy() {
        this.$ws.off('reconnected', this.requests);
        this.$ws.off('cachestats', this.getStats);
    },
    methods: {
        requests() {
            if(!this.hasSetupListeners) {
                this.$ws.on('reconnected', this.requests);
                this.$ws.on('cachestats', this.getStats);
                this.hasSetupListeners = true;
            }

            let account = this.account;
            if(!account) {
                account = this.$store.state.activeAccount;
            }

            this.$ws.send({
                sessionID: 'NotImplemented',
                accountName: account,
                request: 'CacheStats',
                args: {
                    datetime: format(this.dateRange.startDate, "yyyy-MM-dd 00:00:00")
                }
            });
        },
        dateChange(){
            this.tableOptions.items = []
            this.lastUpdated = new Date(0)
            this.requests()
        },
        getStats(event) {
            if(event.response instanceof Object){
                let eventKeys = Object.keys(event.response)
                if(eventKeys.includes("counts")){
                    let counts = event.response.counts
                    if(counts instanceof Object && Object.keys(counts).length > 0){
                        let rows = []
                        for(let key of Object.keys(counts)){
                            rows.push({
                                Amount:counts[key],
                                Type:this.formatProperty(key)
                                })
                        }

                        this.tableOptions.items = rows

                        if(eventKeys.includes("lastUpdated")){
                            this.lastUpdated = new Date(event.response.lastUpdated)
                        }
                    }
                } 
            }
            
        },
        formatProperty(name){
            name = name[0].toUpperCase() + name.slice(1)
            return name.replace(/([A-Z])/g, ' $1').trim()
        },
        onExport() {
            let headers = {};

            for(let column of this.tableOptions.columns) {
                headers[column.key] = column.label;
            }

            let items = map(this.tableOptions.items, clone);
            
            for(let item of items) {
                //escape character to allow commas
                item.timestamp = "\"" + item.timestamp + "\""; 
            }

            exportCsv(headers, items, 'CachedStats');
        },
        
    }
}
</script>